:root {
  --main-bg-color: #171a21;
}

body {
  background-color: var(--main-bg-color);
  margin: auto;
  text-align: center;
  justify-content: center;
  color: rgb(231, 231, 231);
  display: flex;
  flex-direction: column;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
